// Theme variables, must be included before the libraries to allow overriding defaults
@tailwind base;
@tailwind components;
@tailwind utilities;

// 3rd party libraries

// Theme customization
@import './assets/scss/app.scss';

@layer components {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(221, 221, 221, 0.3);
    background-color: #777777;
  }

  ::-webkit-scrollbar {
    height: 6px;
    background-color: white;
  }

  .border-left-dark {
    border-left: 4px solid;
  }

  .blue-b {
    border-color: blue;
  }

  .purple-b {
    border-color: purple;
  }
  .danger-b {
    --tw-border-opacity: 1 !important;
    border-color: rgb(255 77 79 / var(--tw-border-opacity)) !important;
  }

  .connect-sorting-content {
    max-height: calc(100vh - 310px);
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    
}

.breadcrumb-wrapper{
    nz-breadcrumb-separator{
        display: none;
    }
}

.canban-cards{
    border:1px solid transparent;
    &:hover{
        border:1px solid #5cc8f5;
    } 
}
.canban-wrapper{
    .panel{
        padding: 0 !important;
        box-shadow: none !important;
      &:first-child{
        .canban-cards{
            border:2px dashed #C9C9C9;
            &:hover{
                border:2px dashed #5cc8f5;
            }
        }
      }
    }
  }
}

.side-nav-menu {
  .ant-menu-item {
    &:hover {
      svg {
        @apply text-primary w-[20px] h-[20px];
      }
    }
  }
  svg-icon svg {
    @apply text-gray-400 w-[18px] h-[18px];
  }
  .ant-menu-item-selected {
    svg-icon svg {
      @apply text-primary w-[18px] h-[18px];
    }
  }
}
